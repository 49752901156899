<template>
  <div>
    <navbar></navbar>
    <b-button
      size="sm"
      variant="danger"
      class="float-right mauve"
      v-b-modal.userModal
    >
      <font-awesome-icon icon="plus-circle" />
      Nouveau Utilisateur
    </b-button>
    <b-modal
      id="userModal"
      ref="userModal"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <template #modal-header="{ close }">
        <h5>Nouveau Utilisateur</h5>
        <b-button size="sm" @click="close('userModal')">
          <font-awesome-icon icon="times" style="font-size: 30px" />
        </b-button>
      </template>

      <form @submit.prevent="addCAT" class="form-modal-custom-style">
        <b-form-group label="Nom" class="input-modal-champ">
          <b-form-input v-model="newUser.name"></b-form-input>
        </b-form-group>
        <b-form-group label="Email" required class="input-modal-champ">
          <b-form-input
            v-model="newUser.email"
            type="email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Mot de passe" class="input-modal-champ">
          <b-form-input v-model="newUser.password"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirmation mot de passe"
          class="input-modal-champ"
        >
          <b-form-input v-model="newUser.password_confirmation"></b-form-input>
        </b-form-group>
        <b-form-group label="Role" class="input-modal-champ">
          <b-form-select
            v-model="newUser.role"
            :options="options"
            id="cat"
            size="sm"
          ></b-form-select>
        </b-form-group>

        <div class="actionModel">
          <div class="messageError"></div>
          <b-button
            variant="danger"
            type="submit"
            class="succes-btn-modal btn-danger mt-4"
          >
            <div class="block-spinner">
              Confirmer
              <div v-if="getUserLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </div>
          </b-button>
        </div>
      </form>
    </b-modal>

    <div class="table-responsive">
      <table class="content-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Role</th>
            <th>Edit</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in getAllUsers" :key="i">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
            <td class="icon">
              <span
                ><font-awesome-icon
                  icon="pencil-alt"
                  @click.prevent.stop="handleUpdate(user)"
              /></span>
              <span
                ><font-awesome-icon
                  icon="trash"
                  @click.prevent.stop="handleDelete(user)"
                  style="color: red"
              /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
      id="ModelConfig"
      ref="ModelConfig"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <template #modal-header="{ close }">
        <h5>Modifier Utilisateur</h5>
        <b-button size="sm" @click="close('ModelConfig')">
          <font-awesome-icon icon="times" style="font-size: 30px" />
        </b-button>
      </template>

      <form
        @submit.prevent="userModalNew"
        v-if="userToConfig"
        class="form-modal-custom-style"
      >
        <b-form-group label="Nom" class="input-modal-champ">
          <b-form-input
            id="name"
            v-model="userToConfig.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Email" class="input-modal-champ">
          <b-form-input
            id="name"
            type="email"
            v-model="userToConfig.email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Role" class="input-modal-champ">
          <b-form-select
            required
            v-model="userToConfig.role"
            :options="options"
          ></b-form-select>
        </b-form-group>

        <div class="actionModel">
          <div class="messageError"></div>
          <b-button
            variant="danger"
            type="submit"
            class="succes-btn-modal btn-danger mt-4"
          >
            <div class="block-spinner">
              <div v-if="getUserLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
              <div v-else>Modifier</div>
            </div>
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="ModelDelete"
      ref="ModelDelete"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <template #modal-header="{ close }">
        <h5>Supprimer Utilisateur</h5>
        <b-button size="sm" @click="close('ModelDelete')">
          <font-awesome-icon icon="times" style="font-size: 30px" />
        </b-button>
      </template>

      <form
        @submit.prevent="userDelete"
        v-if="userToDelete"
        class="form-modal-custom-style"
      >
        <h2 class="main-color text-center">Supprimer ?</h2>
        <div class="mb-4 text-center red">
          <font-awesome-icon icon="trash" style="font-size: 30px" />
        </div>

        <div class="actionModel">
          <div class="messageError"></div>
          <b-button
            variant="danger"
            type="submit"
            class="succes-btn-modal btn-danger mauve"
          >
            <div class="block-spinner">
              <div v-if="getUserLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
              <div v-else>Supprimer</div>
            </div>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    navbar,
  },
  data() {
    return {
      newUser: {
        name: null,
        email: null,
        role: null,
        password: null,
        password_confirmation: null,
      },
      userToConfig: null,
      userToDelete: null,
      options: [
        { value: 'admin', text: 'Admin' },
        { value: 'user', text: 'User' },
      ],
    };
  },

  methods: {
    ...mapActions(['delete_user', 'all_users', 'edit_user', 'store_user']),

    close(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.newUser = {
        name: null,
        email: null,
        role: null,
        password: null,
        password_confirmation: null,
      };
    },

    handleUpdate(CAT) {
      this.userToConfig = { ...CAT };
      this.$refs['ModelConfig'].show();
    },

    handleDelete(CAT) {
      this.userToDelete = { ...CAT };
      this.$refs['ModelDelete'].show();
    },

    async addCAT() {
      await this.store_user(this.newUser)
        .then(() => {
          this.hideModal('travauxModal');
        })
        .catch(() => {
          this.hideModal('travauxModal');
        });
    },

    async userModalNew() {
      await this.edit_user(this.userToConfig).then(() => {
        this.close('ModelConfig');
      });
    },

    async userDelete() {
      await this.delete_user(this.userToDelete).then(() => {
        this.close('ModelDelete');
      });
    },
  },

  computed: {
    ...mapGetters(['getUserLoading', 'getAllUsers']),
  },
  mounted() {
    this.all_users();
  },
};
</script>

<style lang="scss">
.content-table {
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  width: 95%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  thead tr {
    background-color: #6f42c1;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
  }

  th,
  td {
    padding: 12px 15px;
  }
}

.table-responsive {
  min-width: 100% !important;
  margin: 25px auto;
}

.content-table tbody tr {
  cursor: pointer;
  border-bottom: 1px solid #dddddd;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f3f3f3;
    color: #6f42c1;
  }

  &:last-of-type {
    border-bottom: 1px solid #6f42c1;
  }

  .icon {
    @media only screen and (max-width: 900px) {
      padding: 24px 0;
    }
    display: flex;
    justify-content: space-evenly;
  }
}

.cutsom-modal-bootstrap {
  background-color: #a8a8b81f;
}

.modal-header {
  background-color: #6f42c1;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  & h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    position: relative;
  }

  & h5::after {
    content: '';
    width: 59px;
    border-top: 3px solid red;
    position: absolute;
    left: 0;
    bottom: -4px;
  }

  & button {
    background-color: transparent;
    border: none;
  }
}

.modal-body {
  background-color: #fafdff;
  padding: 14px;

  form {
    margin: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 14px 14px 8px;
    box-shadow: 1px 1px 24px #00000019;

    input,
    select {
      height: 42px;
    }

    select {
      width: 100%;
    }
  }

  .actionModel {
    display: flex;
    justify-content: center;
    & button {
      width: 363px;
    }
    & .block-spinner {
      display: flex;
      justify-content: center;
    }

    & .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.float-right {
  @media only screen and (max-width: 900px) {
    margin-right: 4px;
  }
  float: right;
  margin: 15px 32px 15px 0;
}

.mauve {
  box-shadow: none;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
</style>
